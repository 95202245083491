<template>
    <modal ref="refModal" titulo="Información del pago" guardar tamano="modal-lg" @adicional="guardarConfiguracionPagos">
        <div class="px-3">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <div style="">
                        <!-- valor -->
                        <div class="d-middle my-3">
                            <img src="/img/generales/pago_efectivo.svg" alt="" class="" />
                            <p class="f-500 f-15 ml-2">{{ convertMoney(pago.valor_mes_pago) }}</p>
                        </div>
                        <div class="d-middle my-3">
                            <p class="f-500 f-15 wf-120px">{{ 'Administración' }}</p>
                            <p class="f-300 f-15 ml-2 ucfirst">{{ pago.created_at | helper-fecha('MMMM YYYY') }}</p>
                        </div>
                        <div class="d-middle my-2">
                            <p class="f-500 f-15 wf-120px">{{ 'Registrado por' }}</p>
                            <div class="d-middle">
                                <div class="wh-32 d-middle-center bg-general rounded-circle">
                                    <i class="icon-account-outline text-white f-24" />
                                </div>
                                <p class="f-300 f-15 ml-2">{{ pago.registrado.nombre }} {{ pago.registrado.apellido }}</p>
                            </div>
                        </div>
                        <div class="d-middle my-3">
                            <p class="f-500 f-15 wf-120px lh-18">{{ 'Fecha y hora de registro' }}</p>
                            <p class="f-300 f-15 ml-2">{{ pago.fecha_soporte | helper-fecha('hh:mm a, DD MMM YYYY') }}</p>
                        </div>
                        <div v-if="pago.comentario_residente" class="">
                            <p class="f-500 f-15">{{ 'Comentarios del residente' }}</p>
                            <p class="f-300 f-15 lh-18">{{ 'Pero pago a traves de trasnferencia y me envio el recibo por whatsapp' }}</p>
                        </div>
                        <hr />
                        <div class="d-flex my-2">
                            <p class="f-500 f-15 wf-120px">{{ 'Aprobado por' }}</p>
                            <div class="ml-3">
                                <div v-if="pago.aprobado === 3" class="d-middle">
                                    <div class="wh-32 d-middle-center bg-general rounded-circle">
                                        <i class="icon-account-outline text-white f-24" />
                                    </div>
                                    <p class="f-300 f-15 ml-2">{{ 'Administrador' }}</p>
                                </div>
                                <div v-else class="d-middle mt-3">
                                    <div class="wh-32 d-middle-center bg-general rounded-circle">
                                        <i class="icon-account-outline text-white f-24" />
                                    </div>
                                    <p class="f-300 f-15 ml-2">{{ 'Contador' }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex mt-4">
                            <p class="f-500 f-15 wf-120px">{{ 'Fecha y hora de aprobación' }}</p>
                            <p class="f-15 ml-3">{{ pago.updated_at | helper-fecha('hh:mm a, DD MMM YYYY') }}</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-auto d-middle">
                    <!-- <div class="d-middle-center border br-4" style="width: 300px; height: 300px">
                        <img v-if="pago.imagen" :src="pago.imagen" alt="" class="h-80 w-80" @error="pago.imagen = '/img/no-imagen/noimagenanuncio.svg'" />
                        <img v-else src="/img/no-imagen/noimagenanuncio.svg" alt="" class="h-80 w-80" />
                    </div> -->
                    <galeria-cinco
                    ref="gallriaRef"
                    :listado="pago.imagenes"
                    wPadre="height: 400px; width:320px"
                    wHijo="width:220px"
                    />
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Administracion from '~/services/pago_administracion'

export default {
    props: {
        action: {
            type: Function,
            default: () => {}
        }
    },
    data(){
        return {
            pago: {
                registrado: {
                    nombre: '',
                    apellido: '',
                }
            },
        }
    },
    methods: {
        async toggle(idViviendaPago){
            await this.getRegistroPagoVivienda(idViviendaPago);
            this.$refs.refModal.toggle()
        },
        guardarConfiguracionPagos(){

        },
        async getRegistroPagoVivienda(idViviendaPago){
            try {
                const params = {
                    'idViviendaPago': idViviendaPago
                }
                const { data } = await Administracion.listarRegistroPagoVivienda(params);
                this.pago = data.data;
                if (data.data.imagenes.length > 0) {
                    this.$refs.gallriaRef.seleccionarImg(data.data.imagenes[0].id,data.data.imagenes[0].img);
                }
            } catch (e){
                this.error_catch(e);
            }
        },
    }
}
</script>

<style lang="scss" scoped>
  .contenedor-img{
      width: 112px;
      height: 74px;
  }
</style>