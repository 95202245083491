<template>
    <section class="sec-registro-pagos-detalles p-4">
        <div class="d-middle f-15 gap-2 mb-4">
            <p class="text-blue f-400 cr-pointer" @click="goBack">Registro de pagos</p>
            <i class="icon-right-open text-86 f-16" />
            <p class="f-400">{{ vivienda.torre }} - {{ vivienda.abreviatura }}</p>
        </div>
        <div class="row justify-content-between">
            <div class="col-auto d-middle">
                <el-tooltip content="Regresar" placement="bottom" effect="light" :visible-arrow="false" popper-class="border-0 shadow-sm rounded-pill">
                    <i class="icon-arrow-left-circle f-20 cr-pointer" @click="goBack" />
                </el-tooltip>
                <p v-if="pagosPendientes.length" class="f-20 f-500 ml-2">Pagos pendientes</p>
                <p v-else class="f-20 f-500 ml-2">Historial de pagos</p>
            </div>
            <div class="col-auto my-auto d-middle">
                <div class="d-middle">
                    <p class="f-15 fr-light mr-2">Sin controlar pagos</p>
                    <el-switch v-model="vivienda.pago" :active-value="0" :inactive-value="1" active-color="#5A80EA" inactive-color="#E6E6E6" @change="updateControlPago" />
                </div>
                <div v-if="pagosPendientes.length === 0" class="col-auto">
                    <el-input v-model="search" placeholder="Buscar" size="small" prefix-icon="el-icon-search" clearable />
                </div>
            </div>
        </div>
        <!-- tabla -->
        <div v-if="pagosPendientes.length" class="row mt-4">
            <div class="col-12">
                <el-table :data="pagosPendientes" header-cell-class-name="bg-f5 text-general" header-row-class-name="bg-f5 text-general rounded-circle" @row-click="verDetalles">
                    <template slot="empty">
                        <div class="wM-286px text-center mx-auto py-5">
                            <img src="/img/generales/buscar.svg" alt="" width="120" height="100" />
                            <p class="f-17 f-500 text-general  lh-20">No hemos encontrado coincidencias con tu búsqueda</p>
                        </div>
                    </template>
                    <el-table-column label="Administración" sortable min-width="180">
                        <template #default="scope">
                            <p class="text-general f-16 f-400 ucfirst">{{ scope.row.created_at | helper-fecha('MMMM YYYY') }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="Valor" align="center" sortable min-width="180">
                        <template #default="scope">
                            <p>{{ convertMoney(scope.row.valor_mes_pago) }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="Fecha y hora de registro" sortable min-width="180">
                        <template #default="scope">
                            <p class="text-general f-16">{{ scope.row.fecha_soporte ? scope.row.fecha_soporte : '-- --- ---' | helper-fecha('hh:mm a, DD MMM YYYY') }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="Estado" sortable min-width="180">
                        <template #default="scope">
                            <el-dropdown v-if="scope.$index === 0" trigger="hover">
                                <div :class="`${colorEstados[scope.row.estado]} f-14 fr-light f-400 br-4 w-fit px-2 h-31px d-middle-center cr-pointer`">
                                    {{ textosEstados[scope.row.estado] }}
                                    <i v-if="true" class="icon-down-open ml-1" />
                                </div>
                                <template>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item v-for="(e,index) in estados" :key="index">
                                            <div :class="`${colorEstados[e.id]} f-14 fr-light f-400 br-4 w-fit px-2 h-24px d-middle-center cr-pointer my-2`" @click="cambiarEstado(e, scope.row.id)">
                                                {{ e.nombre }}
                                            </div>
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                            <div v-else :class="`${colorEstados[scope.row.estado]} f-14 fr-light f-400 br-4 w-fit px-2 h-31px d-middle-center cr-pointer`">
                                {{ textosEstados[scope.row.estado] }}
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div v-if="pagosPendientes.length" class="row justify-content-between my-4">
            <div class="col-auto d-middle">
                <i class="icon-calendar" />
                <p class="f-20 f-500 ml-2">Historial de pagos</p>
            </div>
            <div class="col-auto">
                <el-input v-model="search" placeholder="Buscar" size="small" prefix-icon="el-icon-search" clearable />
            </div>
        </div>
        <!-- tabla historial -->
        <div class="row mt-4">
            <div class="col-12">
                <el-table :data="dataHistorial" header-cell-class-name="bg-f5 text-general" header-row-class-name="bg-f5 text-general rounded-circle" @row-click="abrirModalInformacionPago">
                    <template slot="empty">
                        <div class="wM-286px text-center mx-auto py-5">
                            <img src="/img/generales/buscar.svg" alt="" width="120" height="120" />
                            <p class="f-17 f-500 text-general  lh-20">No hemos encontrado coincidencias con tu búsqueda</p>
                        </div>
                    </template>
                    <el-table-column label="Administración" sortable min-width="100">
                        <template #default="scope">
                            <p class="text-general f-16 f-400 ucfirst">{{ scope.row.created_at | helper-fecha('MMMM YYYY') }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="Valor" align="center" sortable min-width="180">
                        <template #default="scope">
                            <p>{{ convertMoney(scope.row.valor_mes_pago) }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="Fecha y hora de registro" sortable min-width="180">
                        <template #default="scope">
                            <p class="text-general f-16">{{ scope.row.fecha_soporte ? scope.row.fecha_soporte : '-' | helper-fecha('hh:mm a, DD MMM YYYY') }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="Aprobado por" sortable min-width="180">
                        <template #default="scope">
                            <div :class="`text-general f-16 f-300`">
                                <i :class="`icon-${scope.row.aprobado === 6 ? 'contador' : 'account-check-outline'} mr-1 f-24`" />
                                {{ aprobadoPor[scope.row.aprobado] }}
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <!-- partials -->
        <modal-info-pago ref="refModalInfoPago" />
        <modal ref="abrirCambiarEstado" titulo="Verificar pago" adicional="Si, cambiar" @adicional="updateEstado">
            <p class="text-center f-500 f-15 text-5d">¿Desea cambiar el estado de este pago a</p>
            <p :class="`${colorTexto[estado]} text-center f-500 f-15 text-5d`">"{{ texto }}" ?</p>
        </modal>
    </section>
</template>
<script>
import Administracion from '~/services/pago_administracion'
import modalInfoPago from './partials/modalInfoPago.vue'

export default {
    components: {
        modalInfoPago,
    },
    data(){
        return {
            search: '',
            estados:[
                {
                    id: 2,
                    nombre: 'Pago sin verificar'
                },
                {
                    id: 3,
                    nombre: 'En mora'
                },
                {
                    id: 4,
                    nombre: 'Paz y Salvo'
                },
            ],
            colorEstados: {
                1: 'bg-pendiente text-white',
                2: 'bg-sin-verificar text-white',
                3: 'bg-mora text-white',
                4: 'bg-paz-salvo text-white',
                5: 'bg-sin-control',
            },
            textosEstados: {
                1: 'Pago pendiente',
                2: 'Pago sin verificar',
                3: 'En mora',
                4: 'Paz y Salvo',
                5: 'Sin control de pago',
            },
            aprobadoPor: {
                3: 'Administrador',
                6: 'Contador',
            },
            pagosPendientes: [],
            historialPagos: [],
            vivienda: {
                pago: 0
            },
            estado: '',
            texto: '',
            idViviendaPago: null,
            colorTexto: {
                3: 'text-danger',
                4: 'text-success',
            }
        }
    },
    computed: {
        dataHistorial(){
            return this.historialPagos.filter(data => !this.search || this.formatearFecha(data.created_at,'MMMM YYYY').includes(this.search.toLowerCase()));
        },
    },
    mounted(){
        this.getViviendaPagos();
        this.getVivienda();
    },
    methods: {
        goBack(){
            this.$router.push({ name: 'pagos.admin.registro.pagos' });
        },
        verDetalles(data){
            this.$router.push({ name: 'pagos.admin.registro.pagos.detail.info', params: {id: this.$route.params.id, id_historial: data.id} });
        },
        abrirModalInformacionPago(item){
            this.$refs.refModalInfoPago.toggle(item.id);
        },
        async getVivienda(){
            try {
                const params = {
                    'idVivienda': this.$route.params.id
                }
                const { data } = await Administracion.listarDetalleVivienda(params);
                this.vivienda = data.data;

            } catch (error){
                console.error(error);
            }
        },
        async getViviendaPagos(){
            try {
                const params = {
                    'idVivienda': this.$route.params.id
                }
                const { data } = await Administracion.listarViviendaRegistroPagos(params);
                this.pagosPendientes = data.data.pagos_pendientes;
                this.historialPagos = data.data.historial_pagos;

            } catch (error){
                console.error(error);
            }
        },
        async updateControlPago(estado){
            try {
                const params = {
                    'id_vivienda': this.$route.params.id,
                    'estado': estado
                }

                const { data } = await Administracion.updateControlPagoVivienda(params);
                if (data.success){
                    this.notificacion('','Actualizado correctamente','success');
                }

            } catch (error){
                console.error(error);
            }
        },
        cambiarEstado(info, idViviendaPago){
            this.estado = info.id;
            this.texto = info.nombre;
            this.idViviendaPago = idViviendaPago;
            this.$refs.abrirCambiarEstado.toggle()
        },
        async updateEstado(){
            try {
                const payload = {
                    'idViviendaPago': this.idViviendaPago,
                    'estado': this.estado,
                }
                const { data } = await Administracion.updateEstadoPago(payload);
                if (data.success){
                    this.$refs.abrirCambiarEstado.toggle();
                    this.notificacion('','Actualizado correctamente','success');
                    this.getViviendaPagos();
                    this.estado = '';
                    this.texto = '';
                }
            } catch (error){
                return this.errorCatch(error);
            }
        }
    },
}
</script>
<style lang="scss" scoped>
$pendientes: #5A80EACC;
$sinVerificar: #FF8316;
$enMora: #FF4F4F;
$pazSalvo: #28D07B;
$sinControl: #868686;
.color{
    &-pendientes{
        color: $pendientes;
    }
    &-sin-verificar{
        color: $sinVerificar;
    }
    &-mora{
        color: $enMora;
    }
    &-paz-salvo{
        color: $pazSalvo;
    }
    &-sin-control{
        color: $sinControl;
    }
}
.bg{
    &-pendiente{
        background: $pendientes;
    }
    &-sin-verificar{
        background: $sinVerificar;
    }
    &-mora{
        background: $enMora;
    }
    &-paz-salvo{
        background: $pazSalvo;
    }
    &-sin-control{
        background: #DBDBDB;
        color: #868686;
    }
}
</style>